import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate} from 'react-router-dom';

const Addbranch = () => {
  const [branch, setBranch] = useState ([]);
  const [loading, setLoading] = useState (true);
  const navigatepass = useNavigate ();
  const [company, SetCompanyname] = useState ();
  const [Phone, SetPhone] = useState ();
  const [Address, SetAddress] = useState ();
  useEffect (() => {
    fetchData ();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get (baseStagingURL + 'list_company');
      console.log (response);
      setBranch (response.data.data);
    } catch (error) {
      console.error ('Error fetching data:', error);
    } finally {
      setLoading (false);
    }
  };

  let history = useNavigate ();

  const handleDelete = id => {
    const confirmDelete = window.confirm (
      'Are you sure you want to delete this Branch?'
    );
    if (confirmDelete) {
      axios
        .get (baseStagingURL + 'remove/company/' + id)
        .then (res => {
          console.log ('Branch deleted successfully:', res.data);
          fetchData ();
        })
        .catch (err => console.error ('Error deleting Branch:', err));
    } else {
      console.log ('Deletion cancel');
    }
  };
  const submitdata = async () => {
    var data = JSON.stringify ({
      company_name: company,
      phone: Phone,
      address: Address,
    });
    var config = {
      method: 'post',
      url: 'http://a-track.fooddishtrading.com/api/user/create_company',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios (config)
      .then (function (response) {
        console.log (JSON.stringify (response.data));
        fetchData ();
      })
      .catch (function (error) {
        console.log (error);
        fetchData ();
      });
  };
  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <p
            className="page-title card"
            style={{
              padding: '2%',
              borderRadius: '20px',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '-2%',
              marginBottom:'1%'
            }}
          >
            <span style={{marginTop: '0%'}}> Branch </span>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
              style={{marginLeft: '80%', marginTop: '-3%' }}
            >
              Add branch
            </button>
          </p>

          <div className="card" style={{borderRadius: '20px'}}>
            <div className="card-body">

              {loading
                ? <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '15%',
                      marginBottom: '15%',
                    }}
                  >
                    {' '}<img src="/images/Spinner-5.gif" alt="Loading GIF" />
                  </div>
                : <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Company Name</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {branch &&
                          branch.map ((cat, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="py-1">{cat.company_name}</td>
                              <td>{cat.phone}</td>
                              <td>{cat.address}</td>
                              <td>
                                <a
                                  onClick={() =>
                                    navigatepass ('/editbranch', {
                                      state: {data: cat},
                                    })}
                                >
                                  <button className="btn btn-success">
                                    Edit
                                  </button>
                                </a>
                                &nbsp;
                                <button
                                  onClick={() => handleDelete (cat.id)}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>}
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="">
                  Activity tracker
                </a>
              </li>
              {/* Add other footer links as needed */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="">Activity tracker</a>
          </div>
        </div>
      </footer>

      {/* Button trigger modal */}

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Employee
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group" style={{margin: '0%'}}>
                <label>Company Name</label>
                <input
                  onChange={e => {
                    SetCompanyname (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="company name"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Phone</label>
                <input
                  onChange={e => {
                    SetPhone (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="phone"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Address</label>
                <textarea
                  onChange={e => {
                    SetAddress (e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter your address"
                  required=""
                  rows="3"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={() => submitdata ()}
                  type="submit"
                  data-dismiss="modal"
                  name="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Addbranch;
