import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Jsonfile = () => {
  const location = useLocation();
  const recdata  =  location.state?.data;
  const id  =  location.state?.id;
  const [valueid, setValueid] = useState ('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [valueDate, setValuedate] = useState ('');
  const [loading, setLoading] = useState (true);
  const [jsonfile, setJsonfile] = useState ('');
  const [jsonData, setJsonData] = useState (null);
  const [Performance, setPerformance] = useState (null);
  const [Mouse, setMouse] = useState (null);
  const [keyStroke, setkeyStroke] = useState (null);
  const [fullname, setName] = useState ('');
  const navigatepass = useNavigate ();
  const [Device_id, setDeviceid] = useState ('');
  const [OS_Type, setOs_type] = useState ('');
  const [Empcode, setEmpcode] = useState ('');
  const [TDate, setTDate] = useState ('');
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${day}-${month}`;
  };
  
  useEffect(() => {
    if (recdata) {  
      console.log(recdata);

      console.log('User ID:', id);
const date = formatDate(recdata)
      setValueid(id);
      setValuedate(date);
    }
  }, [recdata, id]);
  useEffect (() => {
    const ListData = async () => {
      try {
        const response = await axios.get (`http://a-track.fooddishtrading.com/api/user/list/user_json/${valueid}?startDate=${valueDate}&endDate=${valueDate}`
        );
        console.log ('API Responds',response.data);
        const mouse = await Promise.all(
          response.data.data.map(async (item) => {
            const mouseclick = item.mouse_clicks;
            return mouseclick;
          })
        );

    console.log('Total Mouse Clicks:', mouse); 
       console.log(response.data.data);
        const dataArr = await Promise.all(
          response.data.data.map(async (item) => {
            const response = await axios.get(
              baseStagingURL + `json/${item.file_json}`
            );
            return response.data;
          })
        );
        console.log('value',dataArr);
        const mergedArray = [].concat(...dataArr);
        var dataAppdata = mergedArray.map(item=>item.applications)
        var Applicationactivity = mergedArray.map(item=>item.applications_activity)
        const Applicationactivityvalue = [].concat(...Applicationactivity)
        setJsonData(Applicationactivityvalue);
        console.log('dataof json ksdks',dataAppdata);
        const Application = [].concat(...dataAppdata)

        setPerformance(Application);
        console.log('dataAppdata JSON Data: ', mergedArray);
        const deviceId =mergedArray.map(item=>item.Device_id)     
        const Ostype =mergedArray.map(item=>item.OS_Type)   
        //calculating mouse function  
        const MouseCLICK =mergedArray.map(item=>item.mouse_clicks)   
        const totalMouseClicks = MouseCLICK.reduce((sum, clicks) => sum + clicks, 0);
        setMouse(totalMouseClicks);

          //calculating key stoke function  
          const keyStroke =mergedArray.map(item=>item.keystrokes)   
          const totalKeyClicks = keyStroke.reduce((sum, clicks) => sum + clicks, 0);
          setkeyStroke(totalKeyClicks);
          // console.log(totalKeyClicks)
          setMouse(totalMouseClicks);
      
        setDeviceid(deviceId[0]);
        setOs_type(Ostype[0]);
        setTDate (response.data.data[0].date);
        const fileJson = response.data.data[0].file_json;
        setEmpcode (response.data.data[0].emp_code.emp_code);
        setName (response.data.data[0].emp_code.full_name);
        setJsonfile (fileJson);
 
      } catch (error) {
        console.error ('Error fetching data:', error);
      } finally {
        setLoading (false);
      }
    };
    ListData ();
  }, [valueid]);
  useEffect(() => {
    const [day, month, year] = TDate.split('/');
    const dateObject = new Date(`${month}/${day}/${year}`);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = dateObject.getDay();
    const dayName = daysOfWeek[dayIndex];
    setDayOfWeek(dayName);
  }, [TDate]);

  // useEffect (
  //   () => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get (
  //           baseStagingURL + `json/${jsonfile2}`
  //         ); // Absolute URL relative to the public folder
  //         // const data = await response.json ();
  //         console.log ('JSON Data: ', response);
  //         setJsonData (response.data.applications_activity);
  //         setPerformance (response.data.applications);
  //         setkeyStroke (response.data.keystrokes);
  //         setMouse (response.data.mouse_clicks);
  //         setLoading (false);
  //       } catch (error) {
  //         console.error ('Error fetching JSON:', error);
  //         setLoading (false);
  //       }
  //     };

  //     jsonfile && fetchData ();
  //   },
  //   [jsonfile]
  // );

  return (
    <div className="main-panel">
    
      <div className="content">
        <div className="container-fluid">
          {/* <p
            className="page-title card"
            style={{
              padding: '3%',
              borderRadius: '20px',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '0%',
            }}
          >

            <span style={{marginTop: '0%'}}>Employees Activity </span>
          </p> */}
          <div className="card" style={{borderRadius: '20px',marginTop:'-2%'}}>
            <div className="card-body">
              <div
                className="card"
                style={{
                  backgroundColor: '#d37ba4',
                  borderRadius: '20px',
                  padding: '1%',
                  color: 'white',
                  fontWeight: 'bold',
                  // marginLeft:'10px',
                }}
              >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <h6>Name: {fullname}</h6>
             
                    <h6 style={{fontSize:'12px'}}>Emp-code: {Empcode}</h6>
                  </div>
                  <div>
                    <h6 style={{fontSize:'13px'}}>Date:  {TDate}</h6>
                    <h6 style={{fontSize:'13px'}}>Day: {dayOfWeek}</h6>
                  </div>
                </div>
              </div>    
              {loading
                ? <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '15%',
                      marginBottom: '15%',
                    }}
                  >
                    {' '}
                    <img src="/images/Spinner-5.gif" alt="Loading GIF" />
                  </div>
                : <div className="table-responsive" style={{ overflowX: 'hidden' }}>

                    <table
                      className="table table-striped"
                      style={{border: '1px solid black'}}
                    >
                      <thead style={{border: '1px solid black'}}>
                        <tr>
                          <th style={{fontSize:'12px'}}>S.No</th>
                          <th style={{fontSize:'12px'}}>Start time</th>
                          <th style={{fontSize:'12px'}}>Application Name</th>
                          <th style={{fontSize:'12px'}}>Type</th>
                          <th style={{fontSize:'12px'}}>End time</th>
                          <th style={{fontSize:'12px'}}>Total usage</th>
                          <th style={{backgroundColor:'#23ccef',fontSize:'12px',border:'2px solid black'}}>Key stroke</th>
                          <th style={{backgroundColor:'#23ccef',fontSize:'12px',border:'2px solid black'}}>Mouse click</th>
                          {/* <th>Time</th> */}
                          <th style={{fontSize:'12px'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{border: '1px solid black',}}>
                        
                        {Performance &&
                          Performance.map ((PER, index) => (
                            <tr key={index} style={{border: '1px solid black'}}>
                              <td>{index + 1}</td>
                              <td style={{fontSize:'11px',}}>{PER.Open_Time}</td>
                              <td style={{fontSize:'11px'}}>{PER.application_name}</td>
                              <td style={{fontSize:'11px'}}>{PER.type}</td>
                              <td style={{fontSize:'11px'}}>{PER.Close_Time}</td>
                              <td style={{fontSize:'11px'}}>{PER.Total_Usage}</td>
                              <td style={{fontSize:'11px'}}>{PER.key_stroke}</td>
                              <td style={{fontSize:'11px'}}>{PER.mouse_click}</td>
                              {/* <td style={{fontSize:'11px'}}>{PER.time}</td> */}
                              <td style={{fontSize:'11px'}}>
                                {' '}<a
                                  style={{float: 'right'}}
                                  onClick={() =>
                                    navigatepass ('/detailpage', {
                                      state: {data: jsonData, id: PER.ID, Starttime: PER.Open_Time, endtime: PER.Close_Time},
                                    })}
                                >
                                  <button className="btn btn-success">
                                    details
                                  </button>
                                </a>
                              </td>
                            </tr>
                          ))}

                      </tbody>
                    </table>
                    <div className="row" style={{justifyContent:'center'}}>
                      <div className="col-md-3">
                        <div
                          className="card card-stats card-info"
                          style={{borderRadius: '15px'}}
                        >
                          <div className="card-body" style={{height:'60px',marginLeft:'3%'}}>
                            <div className="row">
                              <div className="col-2">
                                <div className="" >
                                  {/* <i className="la la-bar-chart" /> */}
                                  <img
                                    alt="mouse"
                                    style={{
                                      width: '25px',
                                      filter: 'brightness(0) invert(1)',
                                    }}
                                    src="/images/click.png"
                                  />
                                </div>
                              </div>
                              <span style={{paddingLeft:'30%'}}>{Mouse}</span>
                              <div className="col-12 d-flex ">
                                <div className="numbers">
                                  <p className="card-category">
                                    Total Mouse Click
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="card card-stats card-primary"
                          style={{borderRadius: '15px'}}
                        >
                          <div className="card-body " style={{height:'60px',marginLeft:'3%'}}>
                            <div className="row">
                              <div className="col-2">
                                <div>
                                  {/* <i className="la la-check-circle" /> */}
                                  <img
                                    alt="key"
                                    style={{
                                      width: '25px',
                                      filter: 'brightness(0) invert(1)',
                                    }}
                                    src="/images/key.png"
                                  />

                                </div>
                              </div>
                              <span style={{paddingLeft:'30%'}}>{keyStroke}</span>
                              <div className="col-12 d-flex">
                                <div className="numbers">
                                  <p className="card-category">
                                    Total Key stroke
                                  </p>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="card card-stats card-info"
                          style={{borderRadius: '15px'}}
                        >
                          <div className="card-body" style={{height:'60px',marginLeft:'3%'}}>
                            <div className="row" style={{justifyContent:'center'}}>
                              <div className="d-flex">
                                <div className="numbers" >
                                  <p className="card-category" >
                                    OS Type
                                  </p>
                                <span>{OS_Type}</span>
                                  <h4 className="card-title">{Mouse}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div
                          className="card card-stats card-primary"
                          style={{borderRadius: '15px'}}
                        >
                          <div className="card-body" style={{height:'60px',marginLeft:'3%'}}>
                            <div className="row">
                              <div className="d-flex">
                                <div className="numbers">
                                  <p className="card-category" style={{display:'flex', justifyContent:'center'}}>
                                    Device Id
                                 
                                  </p>
                                  <span>{Device_id}</span>
                                  <h4 className="card-title">{Mouse}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    {/* <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Time</th>
                              <th>Type</th>
                              <th>details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jsonData.map ((cat, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="py-1">{cat.time}</td>
                                <td>{cat.type}</td>
                                <td>{cat.details}</td>
                              </tr>
                            ))}

                          </tbody>
                        </table> */}
                  </div>}
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="">
                  Activity tracker
                </a>
              </li>
              {/* Add other footer links as needed */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="https://qcodesinfotech.com" target='blank'>Qcodes</a>
          </div>
        </div>
      </footer>

    </div>
  );
};

export default Jsonfile;
