import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseStagingURL } from "../APIurlconfigure";
import Graph2 from "../graph/Graph2";
import Graph3 from "../graph/graph3";
import Graph4 from "../graph/Graph4";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");
  const startDate = JSON.parse(localStorage.getItem("startdate"));
  const Enddate = JSON.parse(localStorage.getItem("enddate"));
  const [StartDate, setStartDate] = useState(startDate);
  const [endDate, setEndDate] = useState(Enddate);

  const storedDifference = JSON.parse(localStorage.getItem("difference"));
  const userDifference = JSON.parse(localStorage.getItem("totalUsage"));
  const workinghours = JSON.parse(localStorage.getItem("workinghours"));
  const datewisegroupdata = JSON.parse(
    localStorage.getItem("datewisegroupdata")
  );

  console.log("datewisegroupdata", Object.keys(datewisegroupdata).l);
  const [TotalvalueValue, Settotalvaue] = useState();

  const [officeworking, setofficeworking] = useState({
    hours: workinghours ? workinghours.hours : 0,
    minutes: workinghours ? workinghours.minutes : 0,
  });

  const [userworking, setuserworking] = useState({
    hours: userDifference ? userDifference.hours : 0,
    minutes: userDifference ? userDifference.minutes : 0,
  });
  const [selected, setSelected] = useState({
    sign: storedDifference ? storedDifference.sign : "",
    hours: storedDifference ? storedDifference.hours : 0,
    minutes: storedDifference ? storedDifference.minutes : 0,
  });
  const listdays = JSON.parse(localStorage.getItem("dayUsage"));
  const totalDAYS = listdays + 1;
  const calculatingDAYS = totalDAYS * 9;

  const totalUsageObject = {
    hours: calculatingDAYS,
    minutes: 0,
    seconds: 0,
  };

  // console.log ('day', totalUsageObject);
  localStorage.setItem("workinghours", JSON.stringify(totalUsageObject));

  const totalusagetime = JSON.parse(localStorage.getItem("totalUsage"));
  console.log("totalusagetime", totalusagetime);
  const totalUsageMinutes =
    totalusagetime.hours * 60 +
    totalusagetime.minutes +
    totalusagetime.seconds / 60;

  // console.log ('TIME', totalusagetime);
  const differenceInMinutes =
    totalUsageObject.hours * 60 + totalUsageObject.minutes - totalUsageMinutes;
  const sign = differenceInMinutes >= 0 ? "-" : "+";
  const differenceHours = Math.floor(Math.abs(differenceInMinutes) / 60);
  const differenceMinutes = Math.floor(Math.abs(differenceInMinutes) % 60);
  const totalWorkingMinutes = userworking.hours * 60 + userworking.minutes;
  const selectedTimeMinutes =
    (selected.sign === "-" ? -1 : 1) * (selected.hours * 60 + selected.minutes);
  const timeDifference = totalWorkingMinutes - selectedTimeMinutes;
  let buttonColor = "";
  let buttonText = "";

  console.log("time3333333", formattedTime);
  if (selected.sign == "+") {
    buttonColor = "success";
    buttonText = "over time";
  } else if (selected.sign == "-") {
    buttonColor = "danger";
    buttonText = "Less Time";
  } else {
    buttonColor = "warning";
    buttonText = "On Time";
  }
  console.log(
    "Difference:",
    sign,
    differenceHours,
    "hours",
    differenceMinutes,
    "minutes"
  );

  const differenceObject = {
    sign: sign,
    hours: differenceHours,
    minutes: differenceMinutes,
  };
  localStorage.setItem("difference", JSON.stringify(differenceObject));
  const navigate = useNavigate();

  const [count, setCount] = useState();
  let getname = JSON.parse(localStorage.getItem("fullname"));
  const [selectedUser, setSelectedUser] = useState({ name: getname });

  let keystockvalue = JSON.parse(localStorage.getItem("totalkeystocks")) || 0;
  let mouseclickvalue =
    JSON.parse(localStorage.getItem("totalmouseclick")) || 0;

  const [mouseclick, Setmouseclick] = useState({ name: mouseclickvalue });

  const [keyStroke, setkeyStroke] = useState({ name: keystockvalue });
  let keyvalue = JSON.parse(localStorage.getItem("keyvalue")) || 0;
  const [user, setUsercount] = useState();
  const [GetValue, setGetValue] = useState({
    value1: keystockvalue,
    value2: mouseclickvalue,
    value3: keyvalue,
  });
  const handleHistoryClick = (totalActivity) => {
    // Use history.push to navigate to "/detailpage" and pass the data in the state
    navigate("/detailpage", { state: { totalActivity } });
  };
  useEffect(() => {
    const listUser = async () => {
      try {
        const response = await axios.get(baseStagingURL + "list_user");
        const companies = response.data.data;
        const companyCount = companies.length;
        console.log(response);
        setUsercount(companyCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };
    listUser();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseStagingURL + "list_company");
        console.log(response);

        const companies = response.data.data;
        const companyCount = companies.length;
        console.log(companyCount);

        // Assuming you have a state variable named count to store the count
        setCount(companyCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  // Helper function to get the button color based on time difference
  const getTimeDifferenceColor = (dataItem) => {
    const totalHours = Math.floor(
      (dataItem.applicationTotalUsage - dataItem.workinghours) / 3600
    );
    return totalHours >= 0 ? "btn-success" : "btn-danger";
  };
  const totalOfficeHours = officeworking.hours * 60 + officeworking.minutes;
  const userWorkedMinutes = userworking.hours * 60 + userworking.minutes;
  const progressPercentages = (userWorkedMinutes / totalOfficeHours) * 100;

  // Use useState inside your functional component
  const [progressPercentage, setProgressPercentage] =
    useState(progressPercentages);

  // Helper function to get the button text based on time difference
  const getTimeDifferenceText = (dataItem) => {
    let totalSeconds;

    if (dataItem.applicationTotalUsage < dataItem.workinghours) {
      totalSeconds = dataItem.workinghours - dataItem.applicationTotalUsage;
    }

    if (dataItem.applicationTotalUsage > dataItem.workinghours) {
      totalSeconds = dataItem.applicationTotalUsage - dataItem.workinghours;
    }

    if (totalSeconds !== undefined) {
      const totalHours = Math.floor(totalSeconds / 3600);
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60);

      const formattedHours =
        totalHours >= 0 ? totalHours : `${Math.abs(totalHours)}`;
      const formattedMinutes = minutes >= 0 ? minutes : `${Math.abs(minutes)}`;

      return dataItem.applicationTotalUsage < dataItem.workinghours
        ? `Less Time (-${formattedHours}:${formattedMinutes} hours)`
        : `Overtime (${formattedHours}:${formattedMinutes} hours)`;
      return (dataItem.applicationTotalUsage = dataItem.workinghours);
    } else {
      return "No Time Difference";
    }
  };

  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <h4
            className="page-title card"
            style={{ padding: "1%", marginTop: "-2%", borderRadius: "10px" }}
          >
            Dashboard
          </h4>
          <div
            className="row"
            style={{ marginTop: "-1%", marginBottom: "-1%" }}
          >
            <div className="col-md-3">
              <div className="card card-stats card-warning">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        <i className="la la-users" />
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Employees</p>
                        <h4 className="card-title">{user}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-stats card-success">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        <i className="la la-bar-chart" />
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Company</p>
                        <h4 className="card-title">{count}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-stats card-danger">
                <div className="card-body">
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        <i className="la la-newspaper-o" />
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Subscribers</p>
                        <h4 className="card-title">1303</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-stats card-primary">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        <i className="la la-check-circle" />
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Order</p>
                        <h4 className="card-title">576</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="card" >
            <Graph2/>
          </div>
          <br />
          <div>
            <Graph3 getdata={GetValue} />
            </div>
            <br />
            <div>
            <Graph4 getdata={GetValue} />
            </div>
            <br /> */}
          {/* <div
            className="row row-card-no-pd"
            style={{justifyContent: 'center'}}
          >
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Entertainment</h4>
                </div>
                <div className="card-body">
                  <div id="task-complete" className="chart-circle mt-4 mb-3" />
                </div>
                <div className="card-footer">
                  <div className="legend">
                    <i className="la la-circle text-primary" /> Completed
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Social media</h4>
                </div>
                <div className="card-body">
                  <div id="task-complete" className="chart-circle mt-4 mb-3" />
                </div>
                <div className="card-footer">
                  <div className="legend">
                    <i className="la la-circle text-primary" /> Completed
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Creative</h4>
                </div>
                <div className="card-body">
                  <div id="task-complete" className="chart-circle mt-4 mb-3" />
                </div>
                <div className="card-footer">
                  <div className="legend">
                    <i className="la la-circle text-primary" /> Completed
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row row-card-no-pd">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <p
                    style={{ display: "flex", justifyContent: "center" }}
                    className="fw-bold mt-1"
                  >
                    <span
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                      }}
                    >
                      {" "}
                      ({StartDate} & {endDate})
                    </span>
                    {selectedUser.name}
                  </p>
                  <h5 style={{ display: "flex", justifyContent: "center" }}>
                    <b>
                      {userworking.hours}:{userworking.minutes}
                    </b>
                    /
                    <b>
                      {officeworking.hours}:{officeworking.minutes}
                    </b>
                    <span style={{ fontSize: "20px", marginLeft: "2%" }}>
                      {" "}
                      hours
                    </span>
                  </h5>
                  <h4>
                    {/* <b>
                      {selected.sign}x
                      {selected.hours}:{selected.minutes}
                    </b> */}
                  </h4>
                  <a
                    href="#"
                    className={`btn btn-full text-left mt-3 mb-3 btn-${buttonColor}`}
                  >
                    <i className="" style={{ float: "none" }} /> {buttonText}({" "}
                    {
                      <b>
                        {selected.sign}
                        {selected.hours}:{selected.minutes} hours
                      </b>
                    }
                    )
                  </a>
                </div>
                <div className="card-footer">
                  <ul className="nav">
                    <li className="nav-item">
                      <a
                        className="btn btn-default btn-link"
                        href="#"
                        onClick={() => setShowHistory(true)}
                      >
                        <i className="la la-history" /> History
                      </a>
                    </li>
                    {/* <li className="nav-item ml-auto">
                      <a className="btn btn-default btn-link" href="#">
                        <i className="la la-refresh" /> Refresh
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="card-body">
                  <div className="progress-card">
                    <div className="d-flex justify-content-between mb-1">
                      <span className="text-muted">Production</span>
                      <span className="text-muted fw-bold">
                        {userworking.hours}:{userworking.minutes} hours
                      </span>
                    </div>
                    <div className="progress mb-2" style={{ height: 7 }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: `${progressPercentage}%` }}
                        aria-valuenow={`${userworking.hours}:${userworking.minutes}`}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${userworking.hours}:${userworking.minutes}`}
                      />
                    </div>
                  </div>
                  <div className="progress-card">
                    <div className="d-flex justify-content-between mb-1">
                      <span className="text-muted">Idle</span>
                      <span className="text-muted fw-bold"> 576</span>
                    </div>
                    <div className="progress mb-2" style={{ height: 7 }}>
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "65%" }}
                        aria-valuenow={60}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="65%"
                      />
                    </div>
                  </div>
                  <div className="progress-card">
                    <div className="d-flex justify-content-between mb-1">
                      <span className="text-muted">Key Storke</span>
                      <span className="text-muted fw-bold">
                        {keyStroke.name}
                      </span>
                    </div>
                    <div className="progress mb-2" style={{ height: 7 }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${keyStroke.name}%` }}
                        aria-valuenow={keyStroke.name}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={keyStroke.name}
                      />
                    </div>
                  </div>
                  <div className="progress-card">
                    <div className="d-flex justify-content-between mb-1">
                      <span className="text-muted">Mouse click</span>
                      <span className="text-muted fw-bold">
                        {" "}
                        {mouseclick.name}
                      </span>
                    </div>
                    <div className="progress mb-2" style={{ height: 7 }}>
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: `${mouseclick.name}%` }}
                        aria-valuenow={mouseclick.name}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={mouseclick.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card card-stats">
                <div className="card-body">
                  <p className="fw-bold mt-1">Statistic</p>
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center icon-warning">
                        <img
                          alt="mouse"
                          style={{
                            width: "40px",
                            filter: "brightness(110%)",
                          }}
                          src="/images/click2.png"
                        />
                        {/* <i className="la la-pie-chart text-warning" /> */}
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Mouse Click</p>
                        <h4 className="card-title">{mouseclick.name}</h4>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-5">
                      <div className="icon-big text-center">
                        {/* <i className="la la-heart-o text-primary" /> */}
                        <img
                          alt="key"
                          style={{
                            width: "45px",
                            filter:
                              "sepia(10%) hue-rotate(30deg) brightness(110%) contrast(100%)",
                          }}
                          src="/images/key.png"
                        />
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-center">
                      <div className="numbers">
                        <p className="card-category">Key Stroke</p>
                        <h4 className="card-title">{keyStroke.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showHistory &&
            Object.keys(datewisegroupdata) &&
            Object.keys(datewisegroupdata).map((index, item) => (
              <div className="row row-card-no-pd">
                <div className="col-md-4" >
                  <div className="card" >
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      {Object.keys(datewisegroupdata)[item]}
                    </h6>

                    <div className="card-body">
                      <p
                        style={{ display: "flex", justifyContent: "center" }}
                        className="fw-bold mt-1"
                      >
                        {selectedUser.name}
                      </p>
                      <h5 style={{ display: "flex", justifyContent: "center" }}>
                        <b>
                          {Math.floor(
                            Object.values(datewisegroupdata)[item]
                              .applicationTotalUsage / 3600
                          )}{" "}
                          :{" "}
                          {Math.floor(
                            (Object.values(datewisegroupdata)[item]
                              .applicationTotalUsage %
                              3600) /
                              60
                          )}
                        </b>
                        /
                        <b>
                          {Math.floor(
                            Object.values(datewisegroupdata)[item]
                              .workinghours / 3600
                          )}
                          :
                          {Math.floor(
                            (Object.values(datewisegroupdata)[item]
                              .workinghours %
                              3600) /
                              60
                          )}
                        </b>
                        <span style={{ fontSize: "20px", marginLeft: "2%" }}>
                          {" "}
                          hours
                        </span>
                      </h5>
                      <a
                        href="#"
                        className={`btn btn-full text-left mt-3 mb-3 ${getTimeDifferenceColor(
                          Object.values(datewisegroupdata)[item]
                        )}`}
                      >
                        <i className="" style={{ float: "none" }} />{" "}
                        {getTimeDifferenceText(
                          Object.values(datewisegroupdata)[item]
                        )}{" "}
                        {/* {buttonText} */}
                        {/* ( */}
                        {/* {' '}
                        {
                          <b>
                            {(() => {
                              const totalHours = Math.floor (
                                (Object.values (datewisegroupdata)[item]
                                  .applicationTotalUsage -
                                  Object.values (datewisegroupdata)[item]
                                    .workinghours) /
                                  3600
                              );
                              const minutes = Math.floor (
                                (Object.values (datewisegroupdata)[item]
                                  .applicationTotalUsage -
                                  Object.values (datewisegroupdata)[item]
                                    .workinghours) %
                                  3600 /
                                  60
                              );

                              // Check if totalHours is negative
                              const formattedHours = totalHours < 0
                                ? `-${Math.abs (totalHours)}`
                                : totalHours;
                                // setFormattedTime(`${formattedHours}:${Math.abs(minutes)} hours`);
                              return `${formattedHours}:${Math.abs (minutes)} hours`;
                            }) ()}
                          </b>
                        }) */}
                      </a>
                    </div>
                    <div className="card-footer">
                      <ul className="nav">
                        <li className="nav-item">
                          <a
                            className="btn btn-default btn-link"
                            href="/detailpage"
                            onClick={() =>
                              handleHistoryClick(
                                Object.values(datewisegroupdata)[item]
                                  .totalactivity
                              )
                            }
                          >
                            {/* { console.log('value',Object.values (datewisegroupdata)[item].totalactivity)} */}
                            <i className="la la-history" /> History
                          </a>
                        </li>
                        {/* <li className="nav-item ml-auto">
                      <a className="btn btn-default btn-link" href="#">
                        <i className="la la-refresh" /> Refresh
                      </a>
                    </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="progress-card">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-muted">Production</span>
                          <span className="text-muted fw-bold"> $3K</span>
                        </div>
                        <div className="progress mb-2" style={{ height: 7 }}>
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "78%" }}
                            aria-valuenow={12}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="78%"
                          />
                        </div>
                      </div>
                      <div className="progress-card">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-muted">Idle</span>
                          <span className="text-muted fw-bold"> 576</span>
                        </div>
                        <div className="progress mb-2" style={{ height: 7 }}>
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "65%" }}
                            aria-valuenow={60}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="65%"
                          />
                        </div>
                      </div>
                     <div className="progress-card">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-muted">Key Storke</span>
                          <span className="text-muted fw-bold">
                            {
                              Object.values(datewisegroupdata)[item]
                                .totalKeyStrokes
                            }
                          </span>
                        </div>
                        <div className="progress mb-2" style={{ height: 7 }}>
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{
                              width: `${
                                Object.values(datewisegroupdata)[item]
                                  .totalKeyStrokes
                              }%`,
                            }}
                            aria-valuenow={
                              Object.values(datewisegroupdata)[item]
                                .totalKeyStrokes
                            }
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              Object.values(datewisegroupdata)[item]
                                .totalKeyStrokes
                            }
                          />
                        </div>
                      </div>
                      <div className="progress-card">
                        <div className="d-flex justify-content-between mb-1">
                          <span className="text-muted">Mouse click</span>
                          <span className="text-muted fw-bold">
                            {" "}
                            {
                              Object.values(datewisegroupdata)[item]
                                .totalMouseClicks
                            }
                          </span>
                        </div>
                        <div className="progress mb-2" style={{ height: 7 }}>
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{
                              width: `${
                                Object.values(datewisegroupdata)[item]
                                  .totalMouseClicks
                              }%`,
                            }}
                            aria-valuenow={
                              Object.values(datewisegroupdata)[item]
                                .totalMouseClicks
                            }
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              Object.values(datewisegroupdata)[item]
                                .totalMouseClicks
                            }
                          />
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card card-stats">
                    <div className="card-body">
                      <p className="fw-bold mt-1">Statistic</p>
                      <div className="row">
                        <div className="col-5">
                          <div className="icon-big text-center icon-warning">
                            <img
                              alt="mouse"
                              style={{
                                width: "40px",
                                filter: "brightness(110%)",
                              }}
                              src="/images/click2.png"
                            />
                            {/* <i className="la la-pie-chart text-warning" /> */}
                          </div>
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <div className="numbers">
                            <p className="card-category">Mouse Click</p>
                            <h4 className="card-title">
                              {
                                Object.values(datewisegroupdata)[item]
                                  .totalMouseClicks
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-5">
                          <div className="icon-big text-center">
                            {/* <i className="la la-heart-o text-primary" /> */}
                            <img
                              alt="key"
                              style={{
                                width: "45px",
                                filter:
                                  "sepia(10%) hue-rotate(30deg) brightness(110%) contrast(100%)",
                              }}
                              src="/images/key.png"
                            />
                          </div>
                        </div>
                        <div className="col-7 d-flex align-items-center">
                          <div className="numbers">
                            <p className="card-category">Key Stroke</p>
                            <h4 className="card-title">
                              {
                                Object.values(datewisegroupdata)[item]
                                  .totalKeyStrokes
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">2018 Sales</h4>
                  <p className="card-category">Number of products sold</p>
                </div>
                <div className="card-body">
                  <div id="salesChart" className="chart" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header ">
                  <h4 className="card-title">Table</h4>
                  <p className="card-category">Users Table</p>
                </div>
                <div className="card-body">
                  <table className="table table-head-bg-success table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td colSpan={2}>Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card card-tasks">
                <div className="card-header ">
                  <h4 className="card-title">Tasks</h4>
                  <p className="card-category">To Do List</p>
                </div>
                <div className="card-body ">
                  <div className="table-full-width">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input  select-all-checkbox"
                                  type="checkbox"
                                  data-select="checkbox"
                                  data-target=".task-select"
                                />
                                <span className="form-check-sign" />
                              </label>
                            </div>
                          </th>
                          <th>Task</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  className="form-check-input task-select"
                                  type="checkbox"
                                />
                                <span className="form-check-sign" />
                              </label>
                            </div>
                          </td>
                          <td>Finalise the design proposal</td>
                          <td className="td-actions text-right">
                            <div className="form-button-action">
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Edit Task"
                                className="btn btn-link <btn-simple-primary"
                              >
                                <i className="la la-edit" />
                              </button>
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Remove"
                                className="btn btn-link btn-simple-danger"
                              >
                                <i className="la la-times" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer ">
                  <div className="stats">
                    <i className="now-ui-icons loader_refresh spin" /> Updated 3
                    minutes ago
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              {/* <li className="nav-item">
                <a className="nav-link" href="http://www.themekita.com">
                  ThemeKita
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Help
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://themewagon.com/license/#free-item"
                >
                  Licenses
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with <i className="la la-heart heart text-danger" /> by{" "}
            <a href="https://qcodesinfotech.com" target="blank">
              Qcodes
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;
