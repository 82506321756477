import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';

const Datewiselist = () => {
  const navigatepass = useNavigate ();
  const {id} = useParams ();
  const [startDate, setStartDate] = useState ('');
  const [noDataFound, setNoDataFound] = useState (false);
  const [endDate, setEndDate] = useState ('');
  const [data, setData] = useState ('');
  const [loading, setLoading] = useState (false);

  const formatDate = dateString => {
    const date = new Date (dateString);
    const year = date.getFullYear ();
    const month = (date.getMonth () + 1).toString ().padStart (2, '0');
    const day = date.getDate ().toString ().padStart (2, '0'); 
    return `${year}-${month}-${day}`;
  };
  const handleDateSubmit = () => {
    setLoading (true);
    if (startDate && endDate) {
      const formattedStartDate = formatDate (startDate);
      const formattedEndDate = formatDate (endDate);
      const apiUrl = `http://a-track.fooddishtrading.com/api/user/list/user_json/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      axios
        .get (apiUrl)
        .then (function (response) {
          setLoading (false);
          const data = response.data.data;
          const groupedByDate = data.reduce ((result, currentItem) => {
            const date = currentItem.date;
            if (!result[date]) {
              result[date] = [];
            }
            result[date].push (currentItem);
            return result;
          }, {});
          //   setData(groupedByDate);
          const datesArray = Object.keys (groupedByDate);
          if (datesArray.length === 0) {
            // No data found
            setNoDataFound (true);
          } else {
            // Data found, update the state
            setNoDataFound (false);

            setData (datesArray);
          }
          console.log (groupedByDate);
          console.log (Object.values (groupedByDate));
        })
        .catch (function (error) {
          console.log (error);
        });
    } else {
      console.log ('Please select both start and end dates.');
    }
  };
  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <p
            className="page-title card"
            style={{
              padding: '3%',
              borderRadius: '20px',
              fontSize: '30px',
              marginTop: '0%',
            }}
          >
            <span style={{marginTop: '0%'}}>List Activity </span>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <input
                  onChange={e => {
                    setStartDate (e.target.value);
                  }}
                  type="date"
                  className="form-control"
                  name="startdate"
                  placeholder="Start Date"
                  required=""
                />
              </div>
              <div>
                <input
                  onChange={e => {
                    setEndDate (e.target.value);
                  }}
                  type="date"
                  className="form-control"
                  name="enddate"
                  placeholder="End Date"
                  required=""
                />
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleDateSubmit}>
              Submit
            </button>
          </p>
          <div className="card" style={{borderRadius: '20px'}}>
            <div className="card-body">
              {noDataFound
                ? <h5 style={{padding: '10%', marginLeft: '30%'}}>
                    No date found.
                  </h5>
                : <div className="table-responsive">
                    {loading
                      ? <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '15%',
                            marginBottom: '15%',
                          }}
                        >
                          {' '}
                          <img src="/images/Spinner-5.gif" alt="Loading GIF" />
                        </div>
                      : <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data.map ((date, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{date}</td>
                                  <td>
                                    {' '}<a
                                      onClick={() =>
                                        navigatepass ('/Jsonfile', {
                                          state: {data: date, id: id},
                                        })}
                                    >
                                      <button className="btn btn-success">
                                        View
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>}
                  </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datewiselist;
