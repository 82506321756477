import React from 'react';
import ReactApexChart from 'react-apexcharts';

class TrafficChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Key stroke',
          type: 'column',
          data: [],  // Initialize with an empty array
        },
        {
          name: 'Mouse click',
          type: 'line',
          data: [],  // Initialize with an empty array
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: 'Mouse click and Key stroke',
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
         
        ],
        xaxis: {
          type: 'datetime',
        },
        yaxis: [
          {
            title: {
              text: 'Key stroke',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Mouse Click',
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { getdata } = this.props;
    const dates = Object.keys(getdata.value3);
  
    // Sort dates chronologically
    dates.sort((a, b) => new Date(a) - new Date(b));
  
    const mouseClickData = dates.map((date) => getdata.value3[date].totalMouseClicks);

   const keyStokeData = dates.map((date) => getdata.value3[date].totalKeyStrokes);

  
    console.log('meys',mouseClickData);
    this.setState({
      series: [
        { name: 'Key stroke', type: 'column', data: keyStokeData },
        { name: 'Mouse click', type: 'line', data: mouseClickData },
      ],
      options: {
        ...this.state.options,
        labels: dates,
      },
    });
  }
  
  
  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
      </div>
    );
  }
}

export default TrafficChart;
