import React, {useEffect, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
const Graph2 = () => {
  let groupedApplicationstiming = JSON.parse (
    localStorage.getItem ('groupedApplicationstime')
  ) || {};

  const timeStrings = groupedApplicationstiming;
  const [series, setSeries] = useState ([
    {
      name: 'working',
      data: Array(timeStrings.length).fill(0),
    },
  ]);
  console.log ('value0ftime11', timeStrings);
  const timeStringToSeconds = timeString => {
    if (timeString) {
      const [hours, minutes, seconds] = timeString.split (':').map (Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0; 
  };
  useEffect(() => {
    if (timeStrings) {
      const updatedData = series[0].data.map((value, index) => {
        return value + timeStringToSeconds(timeStrings[index]);
      });
  
      setSeries([
        {
          ...series[0],
          data: updatedData,
        },
      ]);
    }
  }, []);
  console.log ('series', series);

  let groupedApplications = JSON.parse (
    localStorage.getItem ('groupedApplications')
  ) || {};

  const categories = groupedApplications;
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    dataLabels: {
      formatter: val => {
        console.log ('value', val);
        // Convert seconds to hours, minutes, and seconds
        const hours = Math.floor (val / 3600);
        const minutes = Math.floor (val % 3600 / 60);
        const seconds = val % 60;

        // Determine the appropriate unit
        let formattedValue = '';
        if (hours > 0) {
          formattedValue = `${hours} ${hours === 1 ? 'hrs' : '(hrs)'}`;
        } else if (minutes > 0) {
          formattedValue = `${minutes} ${minutes === 1 ? 'minute' : '(min)'}`;
        } else {
          formattedValue = `${seconds} ${seconds === 1 ? 'second' : '(s)'}`;
        }
        return formattedValue;
      },
      style: {
        fontSize: '6px', 
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
    yaxis: {
      labels: {
        formatter: function (val) {
          let formattedValue;
    
          if (typeof val === 'object' && 'hours' in val && 'minutes' in val && 'seconds' in val) {
            const { hours, minutes, seconds } = val;
            if (hours > 0) {
              formattedValue = `${hours}.${minutes} hours`;
            }else if (minutes > 0) {
              formattedValue = `00:${minutes}minutes`;
            }else{
              formattedValue = `${seconds} seconds`;
            }
          } else if (typeof val === 'number') {
            const hours = Math.floor(val / 3600);
            const minutes = Math.floor((val % 3600) / 60);
            const seconds = val % 60;
            if (hours > 0) {
              formattedValue = `${hours}.${minutes}hours`;
            }else if (minutes > 0) {
              formattedValue = `00:${minutes} hours`;
            }else{
              formattedValue = `${seconds} seconds`;
            }
          } else {
            console.log('Unexpected val format:', val);
            return 'Invalid Format';
          }
    
          // You can adjust the formatting and styling as needed
          return formattedValue;
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
  };
  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={385}
        />
      </div>
    </div>
  );
};
export default Graph2;
