import React, {useEffect, useState} from 'react';
import Graph2 from './Graph2';
import Graph3 from './graph3';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Graph5 from './graph5';
const Reportgraph = () => {
    let Startdatevalue = JSON.parse(localStorage.getItem('startdate'));
    let Enddatevalue = JSON.parse(localStorage.getItem('enddate'));
    const [selectedstartdate, SetstartdateValue] = useState({ name: Startdatevalue });
    const [selectedenddate, setSelectedenddate] = useState({ name: Enddatevalue });
    let getname = JSON.parse(localStorage.getItem('fullname'));
    const [selectedUser, setSelectedUser] = useState({ name: getname });
    let keystockvalue = JSON.parse(localStorage.getItem('totalkeystocks')) || 0;
    let mouseclickvalue = JSON.parse(localStorage.getItem('totalmouseclick')) || 0;
    let keyvalue = JSON.parse(localStorage.getItem('keyvalue')) || 0;
    const [GetValue,setGetValue] = useState({value1:keystockvalue,value2:mouseclickvalue,value3:keyvalue})
  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
        <div className='col-12'  style={{
          display: 'flex' ,
          justifyContent:'space-between',
          marginRight: '3%',
          alignItems:'center',
          backgroundColor:'#abcbec',
          borderRadius:'15px',
          marginBottom:'10px'
        }}>
      <div style={{marginRight: '40px',marginTop:'1%'}}>
         <h4> {selectedUser.name}</h4>
        </div>
        <div style={{marginRight: '50px',marginTop:'1%'}}>
      <h6>{selectedstartdate.name}</h6>
        </div>
        <div style={{marginRight: '40px',marginTop:'1%'}}>
       <h6> {selectedenddate.name}</h6>
        </div>
      </div>
      <div className='col-12' style={{display:'flex'}}>
     <div className='card col-6'><Graph2 /></div>
      <div className='col-6'><Graph3 getdata={GetValue} /></div>
    </div>
    {/* <div className='col-6 card' ><Graph5 getdata={GetValue} /></div> */}
    </div>
    </div>
    </div>
  );
};

export default Reportgraph;
