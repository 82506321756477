import React from 'react';
import Sidebar from '../src/components/sidebar';
import Header from '../src/components/header';
const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <div className="main-header">
        <Header />
      </div>
      <Sidebar />
      {children}
    </div>
  );
};

export default Layout;