import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Graph3 = ({ getdata }) => {
  React.useEffect(() => {
    console.log('keystockvalue:', getdata.value3);
    // alert(getdata)
  }, [getdata]);

  // Extract dates and corresponding data for Mouse Click and Key Stoke
  const dates = Object.keys(getdata.value3);
  const mouseClickData = dates.map((date) => ({
    y: getdata.value3[date].totalMouseClicks,
    label: date,
  }));
  const keyStokeData = dates.map((date) => ({
    y: getdata.value3[date].totalKeyStrokes,
    label: date,
  }));

  const options = {
    animationEnabled: true,
    title: {
      text: 'Mouse click and Key Stroke',
      fontSize: 20,
    },
    toolTip: {
      shared: true,
    },
    data: [
      {
        type: 'spline',
        name: 'Mouse Click',
        showInLegend: true,
        color: "#ff646d",
        dataPoints: mouseClickData,
      },
      {
        type: 'spline',
        name: 'Key Stroke',
        showInLegend: true,
        color: '#1E90FF',
        dataPoints: keyStokeData,
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default Graph3;
