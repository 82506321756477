import React, { useEffect } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import Listuser from '../src/user/Listuser';
import Dashboard from './dashboard/Dashboard';
import Addbranch from './branch/Addbranch';
import Login from './login/Login';
import Edituser from './user/Edituser';
import Editbranch from './branch/Editbranch';
import Jsonfile from './Listuserjson/Jsonfile';
import Detailpage from './Listuserjson/Detailpage';
import Department from './department/Department';
import EditDepartment from './department/EditDepartment';
import Datewiselist from './Listuserjson/Datewiselist';
import Reportgraph from './graph/Reportgraph';
import Carddatewise from './graph/Carddatewise';

function App () {
//   useEffect(()=>{
// const User = localStorage.getItem('Auth')
// if(!User && window.location.pathname != "/"){
//   window.location='/'
// }
//   },[])
  return (
    <Router>
      <Routes>
        <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
        <Route path="/listempolyees" element={<Layout><Listuser /></Layout>} />
        <Route
          path="/editemployees"
          element={<Layout> <Edituser /> </Layout>}
        />
        <Route path="/listbranch" element={<Layout><Addbranch /></Layout>} />
        <Route path="/editbranch" element={<Layout><Editbranch /></Layout>} />
        <Route path="/Jsonfile"  element={<Layout><Jsonfile /></Layout>}/>
        <Route path="/detailpage" element={<Layout><Detailpage /></Layout>} />
        <Route path="/datawise/:id" element={<Layout><Datewiselist /></Layout>} />
        <Route path="/editdepartment" element={<Layout><EditDepartment /></Layout>} />
        <Route path="/department" element={<Layout><Department /></Layout>} />
        <Route path="/graph" element={<Layout><Reportgraph /></Layout>} />
        <Route path="/carddatewise/:id" element={<Layout><Carddatewise /></Layout>} />
      </Routes>

      <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
    </Router>
  );
}

export default App;
