import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Edituser = (cat) => {
  const navigate = useNavigate(); 
  const location = useLocation();
const recdata  =  location.state?.data;
  const [Name, SetName] = useState ();
  const [Phone, SetPhone] = useState ();
  const [Address, SetAddress] = useState ();
  const [Empid, SetEmpid] = useState ();
  const [createdBy, SetCreatedby] = useState ();
  const [Company, SetCompany] = useState ();
  const [id,SetId] = useState();
  const [CompanyValue,SetCompanyValue] = useState();
   useEffect(()=>{
    const CompanyData = async () => {
      try {
        const response = await axios.get (baseStagingURL + 'list_company');
        console.log (response);
        SetCompanyValue (response.data.data);
      } catch (error) {
        console.error ('Error fetching data:', error);
      } finally {
      }
    };
    CompanyData();
  },[]);
 
  useEffect(()=>{
    if(recdata){
      console.log(recdata);
      SetId(recdata.id)
      SetName(recdata.full_name)
      SetPhone (recdata.phone)
      SetAddress (recdata.address)
      SetEmpid (recdata.emp_code)
      SetCreatedby (recdata.createdBy)
      SetCompany (recdata.Company)
    }
    
    },[recdata])
  const submitdata = async () => {
    var data = JSON.stringify ({
      full_name: Name,
      phone: Phone,
      address: Address,
      emp_code: Empid,
      createdBy: createdBy,
      company: parseInt(Company),
    });
    var config = {
      method: 'post',
      url: `http://a-track.fooddishtrading.com/api/user/update_user/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios (config)
      .then (function (response) {
        console.log (JSON.stringify (response.data));
        if (response.data.status === 200) {
          // Navigate to the listuser page
          navigate('/listempolyees'); // Make sure to replace '/listuser' with the correct route
        } else {
          // Show an alert for unsuccessful login
          alert('Invalid email or password');
        }
      })
      .catch (function (error) {
        console.log (error);
      });
  };
  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <p
            className="page-title card"
            style={{
              padding: '3%',
              borderRadius: '20px',
              fontSize: '20px',
              fontWeight:'bold',
              marginTop: '0%',
            }}
          >
            <span style={{marginTop: '0%'}}>Edit Employees </span>
          </p>

          <div className="card" style={{borderRadius: '20px'}}>
            <div className="card-body">
            <div className="modal-body">
              <div className="form-group" style={{margin: '0%'}}>
                <label>Name</label>
                <input
                value={Name}
                  onChange={e => {
                    SetName (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Full name"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Phone</label>
                <input
                value={Phone}
                  onChange={e => {
                    SetPhone (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="phone"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Address</label>
                <textarea
                value={Address}
                  onChange={e => {
                    SetAddress (e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter your address"
                  required=""
                  rows="3"
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Employee Id</label>
                <input
                value={Empid}
                  onChange={e => {
                    SetEmpid (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="employee id"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Created By</label>
                <input
                value={createdBy}
                  onChange={e => {
                    SetCreatedby (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="created by"
                  required=""
                />
              </div>
              <div className="form-group" style={{ margin: '0%' }}>
      <label>Company</label>
      <select
        onChange={(e) => {
          SetCompany(e.target.value);
        }}
        className="form-control"
        required=""
      >
        <option value="">Select a company</option>
        {CompanyValue && CompanyValue.map((company, index) => (
          <option key={index} value={company.id}>
            {company.company_name}
          </option>
        ))};
      </select>
    </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
                <button
                  onClick={() => submitdata ()}
                  type="submit"
                  data-dismiss="modal"
                  name="submit"
                  className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="">
                Activity tracker
                </a>
              </li>
              {/* Add other footer links as needed */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="https://qcodesinfotech.com" target='blank'>Qcodes</a>
          </div>
        </div>
      </footer>

     

    </div>
  )
}

export default Edituser