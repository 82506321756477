import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Editbranch = () => {
    const navigate = useNavigate(); 
  const location = useLocation();
const recdata  =  location.state?.data;
  const [Phone, SetPhone] = useState ();
  const [Address, SetAddress] = useState ();
  const [Company, SetCompany] = useState ();
  const [id,SetId]=useState();
  useEffect(()=>{
    if(recdata){
      console.log(recdata);
      SetId(recdata.id)
      SetCompany(recdata.company_name)
      SetPhone (recdata.phone)
      SetAddress (recdata.address)
    }
    
    },[recdata])
  const submitdata = async () => {
    var data = JSON.stringify ({
        company_name: Company ,
        address: Address,
        phone: Phone,
    });
    var config = {
      method: 'post',
      url: `http://a-track.fooddishtrading.com/api/user/update/company/${id}`,
       
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios (config)
      .then (function (response) {
        console.log (JSON.stringify (response.data));
        if (response.data.status === 200) {
          // Navigate to the listuser page
          navigate('/listbranch'); // Make sure to replace '/listuser' with the correct route
        } else {
          // Show an alert for unsuccessful login
          alert('Invalid email or password');
        }
      })
      .catch (function (error) {
        console.log (error);
      });
  };
  return (
    <div className="main-panel">
    <div className="content">
      <div className="container-fluid">
        <p
          className="page-title card"
          style={{
            padding: '3%',
            borderRadius: '20px',
            fontSize: '20px',
            fontWeight:'bold',
            marginTop: '0%',
          }}
        >
          <span style={{marginTop: '0%'}}>Edit Employees </span>
        </p>

        <div className="card" style={{borderRadius: '20px'}}>
          <div className="card-body">
          <div className="modal-body">
            <div className="form-group" style={{margin: '0%'}}>
              <label>Company Name</label>
              <input
              value={Company}
                onChange={e => {
                    SetCompany (e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Full name"
                required=""
              />
            </div>
            <div className="form-group" style={{margin: '0%'}}>
              <label>Phone</label>
              <input
              value={Phone}
                onChange={e => {
                  SetPhone (e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="phone"
                required=""
              />
            </div>
            <div className="form-group" style={{margin: '0%'}}>
              <label>Address</label>
              <textarea
              value={Address}
                onChange={e => {
                  SetAddress (e.target.value);
                }}
                className="form-control"
                placeholder="Enter your address"
                required=""
                rows="3"
              />
            </div>
           
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal">
                Close
              </button>
              <button
                onClick={() => submitdata ()}
                type="submit"
                data-dismiss="modal"
                name="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="footer">
      <div className="container-fluid">
        <nav className="pull-left">
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link" href="">
              Activity tracker
              </a>
            </li>
            {/* Add other footer links as needed */}
          </ul>
        </nav>
        <div className="copyright ml-auto">
          2024, made with
          {' '}
          <i className="la la-heart heart text-danger" />
          {' '}
          by
          {' '}
          <a href="">Activity tracker</a>
        </div>
      </div>
    </footer>

   

  </div>
  )
}

export default Editbranch