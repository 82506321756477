import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {useNavigate, useParams} from 'react-router-dom';

const Carddatewise = () => {
  let getname = JSON.parse (localStorage.getItem ('fullname'));
  const [selectedUser, setSelectedUser] = useState ({name: getname});
  const [selectedUservalue, setSelectedUservalue] = useState ('');
  const {id} = useParams ();
  const navigate = useNavigate ();
  const [startDate, setStartDate] = useState ('');
  const [endDate, setEndDate] = useState ('');
  const [Data, setData] = useState ('');
  const formatDate = dateString => {
    const date = new Date (dateString);
    const year = date.getFullYear ();
    const month = (date.getMonth () + 1).toString ().padStart (2, '0'); // Ensures leading zero for months less than 10
    const day = date.getDate ().toString ().padStart (2, '0'); // Ensures leading zero for days less than 10
    return `${year}-${month}-${day}`;
  };
  useEffect (() => {
    const fetchData = async () => {
      try {
        const response = await axios.get (baseStagingURL + 'list_user');
        console.log (response);
        setSelectedUservalue (response.data.data);
      } catch (error) {
        console.error ('Error fetching data:', error);
      }
    };
    fetchData ();
  }, []);
  useEffect (
    () => {
      if (Array.isArray (selectedUservalue)) {
        const selectedvalue = selectedUservalue.filter (val => val.id == id);
        if (selectedvalue && selectedvalue.length > 0) {
          const fullName = selectedvalue.map (item => item.full_name);
          localStorage.setItem ('fullname', JSON.stringify (fullName));
          setSelectedUser ({name: fullName});
        } else {
          console.error ('No matching user found for the given id:', id);
        }
      } else {
        console.error ('selectedUservalue is not an array:', selectedUservalue);
      }
    },
    [id, selectedUservalue]
  );
  console.log ('user', selectedUser);
  const handleFilterButtonClick = async () => {
    if (startDate && endDate && id) {
      localStorage.setItem ('startdate', JSON.stringify (startDate));
      localStorage.setItem ('enddate', JSON.stringify (endDate));
      const formattedStartDate = formatDate (startDate);
      const formattedEndDate = formatDate (endDate);
      const apiUrl = `http://a-track.fooddishtrading.com/api/user/list/user_json/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      try {
        const response = await axios.get (apiUrl);
        const data = response.data.data;
        console.log ('DATA', data);
        const dataArr = await Promise.all (
          data &&
            data.map (async item => {
              const innerResponse = await axios.get (
                baseStagingURL + `json/${item.file_json}`
              );
              return innerResponse.data;
            })
        );
        const mergedArray = [].concat (...dataArr);
        const ApplicationArray = [].concat (
          ...mergedArray.map (item => item.applications)
        );
        console.log('value of data', mergedArray);

        const totalsByDate = {};
        
        mergedArray.forEach(item => {
          const date = item.date;
        
          if (!totalsByDate[date]) {
            totalsByDate[date] = {
              totalKeyStrokes: 0,
              totalMouseClicks: 0,
              application: [], 
              workinghours:32400, 
              totalactivity: [], 
              applicationTotalUsage: 0, 
            };
          }
        
          // Add keystrokes and mouse clicks to the totals
          totalsByDate[date].totalKeyStrokes += item.keystrokes;
          totalsByDate[date].totalMouseClicks += item.mouse_clicks;
        
          // Ensure 'application' and 'totalactivity' are initialized as arrays
          totalsByDate[date].application = totalsByDate[date].application || [];
          totalsByDate[date].totalactivity = totalsByDate[date].totalactivity || [];
        
          totalsByDate[date].application = totalsByDate[date].application.concat(item.applications);
          totalsByDate[date].totalactivity = totalsByDate[date].totalactivity.concat(item.applications_activity);
        
          // Calculate total usage time for each application and add to the total
          item.applications.forEach(app => {
            // Convert Total_Usage to seconds and add to the total
            const totalUsageInSeconds =
              parseInt(app.Total_Usage.split(':')[0]) * 3600 +
              parseInt(app.Total_Usage.split(':')[1]) * 60 +
              parseInt(app.Total_Usage.split(':')[2]);
            
            totalsByDate[date].applicationTotalUsage += totalUsageInSeconds;
          });
        });
        
        console.log('Totals by Date:', totalsByDate);
        
        
        localStorage.setItem (
          'datewisegroupdata',
          JSON.stringify (totalsByDate)
        );

        // Now totalsByDate object contains totals for each date
        // console.log('grouby date',totalsByDate);

        const totalKeyStrokes = mergedArray.reduce (
          (acc, item) => acc + item.keystrokes,
          0
        );
        const totalMouseClicks = mergedArray.reduce (
          (acc, item) => acc + item.mouse_clicks,
          0
        );

        // console.log("Total Key Strokes:", totalKeyStrokes);
        // console.log("Total Mouse Clicks:", totalMouseClicks);
        localStorage.setItem ('keyvalue', JSON.stringify (totalsByDate));
        localStorage.setItem (
          'totalkeystocks',
          JSON.stringify (totalKeyStrokes)
        );
        localStorage.setItem (
          'totalmouseclick',
          JSON.stringify (totalMouseClicks)
        );

        let keystockvalue =
          JSON.parse (localStorage.getItem ('totalkeystocks')) || 0;
        let mouseclickvalue =
          JSON.parse (localStorage.getItem ('totalmouseclick')) || 0;
        let keyvalue = JSON.parse (localStorage.getItem ('keyvalue')) || 0;

        // setdata({value1:keystockvalue,value2:mouseclickvalue,value3:keyvalue})
        // console.log('value of data',ApplicationArray);
        // Grouping by ID
        const groupedById = ApplicationArray.reduce ((result, item) => {
          const id = item.ID;
          if (!result[id]) {
            result[id] = [];
          }
          result[id].push (item);
          return result;
        }, {});
        const result = [];
        var arrData = Object.values (groupedById);
        ////checking
        const mergedArray1 = [].concat (...arrData);

        var totalUsageInSeconds = mergedArray1.reduce ((total, item) => {
          console.log (item.Total_Usage);
          if (Array.isArray (item.Total_Usage)) {
            // If Total_Usage is an array, iterate through its elements
            item.Total_Usage.forEach (usage => {
              if (
                usage &&
                typeof usage === 'object' &&
                usage.Total_Usage &&
                typeof usage.Total_Usage === 'string'
              ) {
                const [hours, minutes, seconds] = usage.Total_Usage
                  .split (':')
                  .map (part => parseInt (part, 10));

                if (!isNaN (hours) && !isNaN (minutes) && !isNaN (seconds)) {
                  total += hours * 3600 + minutes * 60 + seconds;
                } else {
                  console.log (
                    `Invalid Total_Usage format in item: ${JSON.stringify (item)}`
                  );
                }
              } else {
                console.log (
                  `Invalid Total_Usage element in item: ${JSON.stringify (item)}`
                );
              }
            });
          } else if (typeof item.Total_Usage === 'string') {
            // If Total_Usage is a string, process it as before
            const [hours, minutes, seconds] = item.Total_Usage
              .split (':')
              .map (part => parseInt (part, 10));

            if (!isNaN (hours) && !isNaN (minutes) && !isNaN (seconds)) {
              total += hours * 3600 + minutes * 60 + seconds;
            } else {
              console.log (
                `Invalid Total_Usage format in item: ${JSON.stringify (item)}`
              );
            }
          } else {
            console.log (
              `Invalid Total_Usage in item: ${JSON.stringify (item)}`
            );
          }

          return total;
        }, 0);
        const totalHours = Math.floor (totalUsageInSeconds / 3600);
        const totalMinutes = Math.floor (totalUsageInSeconds % 3600 / 60);
        const totalSeconds = totalUsageInSeconds % 60;

        console.log (
          `Total Usage: ${totalHours} hours, ${totalMinutes} minutes, ${totalSeconds} seconds`
        );
        const totalUsageObject = {
          hours: totalHours,
          minutes: totalMinutes,
          seconds: totalSeconds,
        };

        // Convert the object to a JSON string
        const totalUsageString = JSON.stringify (totalUsageObject);

        // Store the JSON string in local storage
        localStorage.setItem ('totalUsage', totalUsageString);
        // Retrieve the start and end dates from local storage
        // Retrieve the start and end dates from local storage
        // Retrieve the start and end dates from local storage
        const storedStartDate = JSON.parse (localStorage.getItem ('startdate'));
        const storedEndDate = JSON.parse (localStorage.getItem ('enddate'));

        console.log ('Parsed start date:', storedStartDate);
        console.log ('Parsed end date:', storedEndDate);

        // Create Date objects
        const startDate = new Date (storedStartDate);
        const endDate = new Date (storedEndDate);

        console.log ('Original start date:', startDate);
        console.log ('Original end date:', endDate);

        // Set the time components to midnight (00:00:00)
        startDate.setHours (0, 0, 0, 0);
        endDate.setHours (0, 0, 0, 0);

        console.log ('Adjusted start date:', startDate);
        console.log ('Adjusted end date:', endDate);

        // Calculate the difference in milliseconds
        const timeDifference = endDate - startDate;

        // Calculate the difference in days
        const daysDifference = Math.ceil (
          timeDifference / (1000 * 60 * 60 * 24)
        );

        console.log (
          'Number of days between start and end dates:',
          daysDifference
        );

        localStorage.setItem ('dayUsage', JSON.stringify (daysDifference));
        console.log ('arraydata of the list', groupedById);
        // Iterate through the data and calculate total usage for each application
        arrData.forEach (entry => {
          entry.forEach (item => {
            const existingApp = result.find (
              app => app.application_name === item.application_name
            );
            if (existingApp) {
              // Add the total usage to the existing application
              const totalUsageArray = existingApp.Total_Usage
                .split (':')
                .map (Number);
              const itemUsageArray = item.Total_Usage.split (':').map (Number);
              for (let i = 0; i < totalUsageArray.length; i++) {
                totalUsageArray[i] += itemUsageArray[i];
              }
              existingApp.Total_Usage = `${totalUsageArray[0]}:${totalUsageArray[1]}:${totalUsageArray[2]}`;
            } else {
              // Add a new entry for the application
              result.push ({
                application_name: item.application_name,
                Total_Usage: item.Total_Usage,
              });
            }
          });
        });
        const applicationFetsh = result.map (item => item.application_name);
        localStorage.setItem (
          'groupedApplications',
          JSON.stringify (applicationFetsh)
        );
        const applicationtiming = result.map (item => item.Total_Usage);
        localStorage.setItem (
          'groupedApplicationstime',
          JSON.stringify (applicationtiming)
        );
        const groupedByDate = dataArr.reduce ((result, currentItem) => {
          const date = currentItem.date;
          if (!result[date]) {
            result[date] = [];
          }
          result[date].push (currentItem);
          return result;
        }, {});

        const datesArray = Object.keys (groupedByDate);
        if (datesArray.length === 0) {
          // No data found
        } else {
          // Data found, update the state
          setData (datesArray);
        }
        console.log (groupedByDate);

        console.log (Object.values (groupedByDate));
        navigate (`/graph`);
      } catch (error) {
        console.log (error);
      }
    } else {
      console.log ('Please select both start and end dates.');
    }
  };
  const handleInputChange = event => {
    const {name, value} = event.target;
    if (name === 'startDate') {
      setStartDate (value);
    } else if (name === 'endDate') {
      setEndDate (value);
    }
  };

  return (
    <div className="main-panel">
      <div className="content">
        <div
          className="container-fluid"
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <div
            className="card"
            style={{width: '60%', height: '400px', borderRadius: '20px'}}
          >
            <p
              className="page-title"
              style={{
                padding: '0%',
                borderRadius: '20px',
                fontSize: '30px',
                marginTop: '0%',
                marginBottom: '3%',
              }}
            >
              <span
                className="card"
                style={{
                  marginTop: '0%',
                  padding: '1%',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  backgroundColor: '#23ccef',
                  alignItems: 'center',
                }}
              >
                User Activity{' '}
              </span>
              <div>
                <div style={{marginBottom: '10%', margin: '20px'}}>
                  <h6> Start date</h6>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleInputChange}
                    style={{
                      width: '100%',
                      fontSize: '16px',
                      alignItems: 'center',
                    }}
                  />
                </div>
                <div style={{marginBottom: '10%', margin: '20px'}}>
                  <h6> End date</h6>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleInputChange}
                    style={{width: '100%', fontSize: '16px'}}
                  />
                </div>
              </div>
              <button
                style={{margin: '20px', float: 'right'}}
                onClick={handleFilterButtonClick}
                className="btn btn-primary"
              >
                Submit
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carddatewise;
