import React from 'react';
import {useNavigate} from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate ();

  const submitLogout = () => {
    localStorage.removeItem ('Auth');
    navigate ('/');
  };

  return (
    <div>
      <div
        className="logo-header"
        style={{border: 'none', fontFamily: 'monospace', fontSize: '25px'}}
      >
        <a href="#" className="logo" style={{color: 'black'}}>
          Activity tracker
        </a>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-controls="sidebar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <button className="topbar-toggler more">
          <i className="la la-ellipsis-v" />
        </button>
      </div>
      <nav className="navbar navbar-header navbar-expand-lg">
        <div className="container-fluid">
          <form
            className="navbar-left navbar-form nav-search mr-md-3"
            action=""
          >
            <div className="input-group">
              <input
                type="text"
                placeholder="Search ..."
                className="form-control"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <i className="la la-search search-icon" />
                </span>
              </div>
            </div>
          </form>
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item dropdown hidden-caret">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-envelope" />
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </li>
            <li className="nav-item dropdown hidden-caret">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-bell" />
                <span className="notification">3</span>
              </a>
              <ul
                className="dropdown-menu notif-box"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <div className="dropdown-title">
                    You have 4 new notification
                  </div>
                </li>
                <li>
                  <div className="notif-center">
                    <a href="#">
                      <div className="notif-icon notif-primary">
                        {' '}
                        <i className="la la-user-plus" />{' '}
                      </div>
                      <div className="notif-content">
                        <span className="block">New user registered</span>
                        <span className="time">5 minutes ago</span>
                      </div>
                    </a>
                    <a href="#">
                      <div className="notif-icon notif-success">
                        {' '}
                        <i className="la la-comment" />{' '}
                      </div>
                      <div className="notif-content">
                        <span className="block">Rahmad commented on Admin</span>
                        <span className="time">12 minutes ago</span>
                      </div>
                    </a>
                    <a href="#">
                      <div className="notif-img">
                        <img src="assets/img/profile2.jpg" alt="Img Profile" />
                      </div>
                      <div className="notif-content">
                        <span className="block">Reza send messages to you</span>
                        <span className="time">12 minutes ago</span>
                      </div>
                    </a>
                    <a href="#">
                      <div className="notif-icon notif-danger">
                        {' '}
                        <i className="la la-heart" />{' '}
                      </div>
                      <div className="notif-content">
                        <span className="block">Farrah liked Admin</span>
                        <span className="time">17 minutes ago</span>
                      </div>
                    </a>
                  </div>
                </li>
                <li>
                  <a className="see-all" href="javascript:void(0);">
                    {' '}
                    <strong>See all notifications</strong>{' '}
                    <i className="la la-angle-right" />{' '}
                  </a>
                </li>
              </ul>
            </li>
            <button
              onClick={() => submitLogout ()}
              style={{border: 'none', padding: 0, background: 'none'}}
            >
              <a className="dropdown-item" href="#">
                <i className="fa fa-power-off" /> Logout
              </a>
            </button>

          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
