import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
const Detailpage = () => {
  const [Datavalue, setDatavalue] = useState ('');
  const [Application,SetApplication] = useState ('');
  const [StartTime,SetStarttime] = useState ('');

  const [EndTime,SetEndtime] = useState ('');
  const [Activity,SetActivity] = useState ('');
    const location = useLocation();
    const jsonData  =  location.state?.totalActivity;
    console.log('datassssss',jsonData)
    const ApplicationID  =  location.state?.id;
    const Starttime1 = location.state?.Starttime;
const startTime = new Date(Starttime1);
const timeString = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

    const Endtime  =  location.state?.endtime;
    const EndTime1 = new Date(Endtime);
const ENDString = EndTime1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  useEffect(()=>{
    if(jsonData){
      SetStarttime(timeString);
      SetEndtime(ENDString);
      const dataArr=jsonData;
      console.log('filtervalue',dataArr);
      var dataAppdata = jsonData.map(item=>item.Application_name);
      SetApplication(dataAppdata[0]);
      setDatavalue(dataArr)
      const functionValue = dataArr.map(item=>item.mouse_clicks);
      const Application = [].concat(...functionValue)
      SetActivity(Application);
    }
    
    },[jsonData])
 
  return (
   
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          {/* {Activity && JSON.stringify(Activity)} */}
          <div className="card" style={{borderRadius: '20px',marginTop:'-2%'}}>
            <div className="card-body">
            <div className="card" style={{backgroundColor:'#d37ba4',borderRadius: '20px',padding:'3%',color:'white',fontWeight:'bold',height:'30px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
               <div style={{marginTop:'-1%'}}>
              {/* <h6>Application :<span style={{color:'black'}}> {Application} </span></h6> */}
              <h6 style={{color:'black'}}> Over All Activity Report</h6>
                </div>
                {/* <div style={{ display: 'flex' ,marginTop:'-1%' }}>
  <h6 style={{ fontSize: '14px', marginRight: '8px' }}>{StartTime}</h6>
  <p style={{ fontSize: '14px', marginRight: '8px' }}>&&</p>
  <h6 style={{ fontSize: '14px' }}>{EndTime}</h6>
</div> */}
              </div>
             </div>
             <div style={{ marginLeft: '1%', display: 'flex', flexWrap: 'wrap' }}>
  {Activity &&
    Activity.map((cat, index) => (
      <div
        key={index}
        className="col-5"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '0px',
          marginRight: '10px', // Add margin between columns
        }}
      >
        <div style={{ borderRight: '2px dotted black', paddingLeft: '10px', paddingBottom: '10px' }}>
          <h6 style={{ fontSize: '13px' }}>{cat.time}<span style={{ marginLeft: '10px', width: '10px' }}>-</span></h6>
        </div>   
        <div style={{ fontSize: '13px', marginTop: '-6.5%', marginLeft: '10px' }}>
          <span style={{ marginRight: '10px', width: '10px' }}>-  </span>
          <span style={{ color: '#23ccefd4' }}>{cat.details}</span>
          <span style={{ fontSize: '13px' }}>({cat.type})</span>
        </div>
      </div>
    ))}
</div>            
                    {/* <div className="table-responsive">          
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Time</th>
                              <th>Type</th>
                              <th>details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jsonData.map ((cat, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="py-1">{cat.time}</td>
                                <td>{cat.type}</td>
                                <td>{cat.details}</td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="">
                  Activity tracker
                </a>
              </li>
              {/* Add other footer links as needed */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="https://qcodesinfotech.com" target='blank'>Qcodes</a>
          </div>        
        </div>
      </footer>

    </div>
  )
}

export default Detailpage