import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate} from 'react-router-dom';
const Department = () => {
  const [CompanyValue, SetCompanyValue] = useState ();
  const [Department, SetDepartment] = useState ();
  const [Departmentvalue, SetDepartmentvalue] = useState ();
  const navigatepass = useNavigate ();
  const [Company, SetCompany] = useState ();
  const [loading, setLoading] = useState (true);
  useEffect(() => {
    fetchData();
  }, []);
    const fetchData = async () => {
      
      try {
        const [departmentResponse, companyResponse] = await Promise.all([
          axios.get(baseStagingURL + 'list/department'),
          axios.get(baseStagingURL + 'list_company')
        ]);
        console.log(departmentResponse);
        console.log(companyResponse);
        SetDepartmentvalue(departmentResponse.data.data);
        SetCompanyValue(companyResponse.data.data);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  const submitdata = async () => {
    var data = JSON.stringify ({
      title: Department,
      company: parseInt (Company),
    });
    var config = {
      method: 'post',
      url: 'http://a-track.fooddishtrading.com/api/user/create/department',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios (config)
      .then (function (response) {
        console.log (JSON.stringify (response.data));
        fetchData ();
      })
      .catch (function (error) {
        console.log (error);
      });
  };
 
  const handleDelete = id => {
    const confirmDelete = window.confirm (
      'Are you sure you want to delete this department?'
    );
    if (confirmDelete) {
      axios
        .get (baseStagingURL + 'remove/department/' + id)
        .then (res => {
          console.log ('department deleted successfully:', res.data); 
          fetchData ();
        })

        .catch (err => console.error ('Error deleting Empolye:', err));
    } else {
      console.log ('Deletion cancel');
    }
  };
  return (
    <div className="main-panel">
      <div className="content">
        <div className="container-fluid">
          <p
            className="page-title card"
            style={{
              padding: '2%',
              borderRadius: '20px',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '-2%',
              marginBottom:'1%'
            }}
          >
            <span style={{marginTop: '0%'}}> Department </span>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
              style={{marginLeft: '80%', marginTop: '-3%'}}
            >
              Add Department
            </button>
          </p>
          <div className="card" style={{borderRadius: '20px'}}>
            <div className="card-body">
              {loading
                ? <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '10%',
                      marginBottom: '5%',
                    }}
                  >
                    {' '}<img src="/images/Spinner-5.gif" alt="Loading GIF" />
                  </div>
                : <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Department Name</th>
                          <th>Company name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Departmentvalue &&
                          Departmentvalue.map ((cat, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="py-1">{cat.title}</td>
                              <td className="py-1">
                                {cat.company.company_name}
                              </td>
                              <td>
                                <a
                                  onClick={() =>
                                    navigatepass ('/editdepartment', {
                                      state: {data: cat},
                                    })}
                                >
                                  <button className="btn btn-success">
                                    Edit
                                  </button>
                                </a>
                                &nbsp;
                                <button
                                  onClick={() => handleDelete (cat.id)}
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>}
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="">
                  Activity tracker
                </a>
              </li>
              {/* Add other footer links as needed */}
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="https://qcodesinfotech.com" target='blank'>Qcodes</a>
          </div>
        </div>
      </footer>

      {/* Button trigger modal */}

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Employee
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group" style={{margin: '0%'}}>
                <label>Department Name</label>
                <input
                  onChange={e => {
                    SetDepartment (e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Department name"
                  required=""
                />
              </div>
              <div className="form-group" style={{margin: '0%'}}>
                <label>Company Name</label>
                <select
                  onChange={e => {
                    SetCompany (e.target.value);
                  }}
                  className="form-control"
                  required=""
                >
                  <option value="">Select a company</option>
                  {CompanyValue &&
                    CompanyValue.map ((company, index) => (
                      <option key={index} value={company.id}>
                        {company.company_name}
                      </option>
                    ))};
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={() => submitdata ()}
                  type="submit"
                  data-dismiss="modal"
                  name="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default Department;
