import React from 'react';

const sidebar = () => {
  return (
    <div className="sidebar">
      <div className="scrollbar-inner sidebar-wrapper">
        <div className="user" style={{marginTop: '10%'}}>
          <div className="photo">
            <img src="/assets/img/qc.png" />
          </div>
          <div className="info">
            <a
              className=""
              data-toggle="collapse"
              href="#collapseExample"
              aria-expanded="true"
            >
              <span className="">Admin</span>
            </a>
            <div className="clearfix" />
            <div
              className="collapse in"
              id="collapseExample"
              aria-expanded="true"
              style={{}}
            >
              {/* <ul className="nav">
              <li>
                <a href="#profile">
                  <span className="link-collapse">My Profile</span>
                </a>
              </li>
              <li>
                <a href="#edit">
                  <span className="link-collapse">Edit Profile</span>
                </a>
              </li>
              <li>
                <a href="#settings">
                  <span className="link-collapse">Settings</span>
                </a>
              </li>
            </ul> */}
            </div>
          </div>
        </div>
        <ul className="nav">
          <li className="nav-item active">
            <a href="/dashboard">
              <i className="la la-dashboard" />
              <p>Dashboard</p>
            </a>
          </li>
          <li className="nav-item  active">
            <a href="#">
              <i className="la la-table" />
              <p>Office</p>
            </a>
            <ul>
              <li className="nav-item active">
                <a href="/listbranch">Branch</a>
              </li>
              <li className="nav-item active">
                <a href="/department">Department</a>
              </li>
              <li className="nav-item active">
                <a href="/listempolyees">Employees</a>
              </li>
            </ul>
          </li>
          <li className="nav-item  active">
            <a href="#">
              <i className="la la-keyboard-o" />
              <p>Setting</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default sidebar;
