import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {baseStagingURL} from '../APIurlconfigure';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const EditDepartment = () => {

  const navigate = useNavigate(); 
  const location = useLocation();
  const recdata  =  location.state?.data;
  const [Company, SetCompany] = useState ();
  const [id,SetId]=useState();
  const [CompanyValue, SetCompanyValue] = useState ();
  const [Department, SetDepartment] = useState ('');



  useEffect (() => {
    const CompanyData = async () => {
      try {
        const response = await axios.get (baseStagingURL + 'list_company');
        console.log (response);
        SetCompanyValue (response.data.data);
      } catch (error) {
        console.error ('Error fetching data:', error);
      } finally {
      }
    };
    CompanyData ();
  }, []);
  useEffect(()=>{
    if(recdata){
      console.log(recdata);
      SetId(recdata.id)
      SetDepartment(recdata.title)
  
    }
    
    },[recdata])
  const submitdata = async () => {
    var data = JSON.stringify ({
        title: Department,
        company: parseInt (Company),
    });
    var config = {
      method: 'post',
      url: `http://a-track.fooddishtrading.com/api/user/update/department/${id}`,
       
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios (config)
      .then (function (response) {
        console.log (JSON.stringify (response.data));
        if (response.data.status === 200) {
          // Navigate to the listuser page
          navigate('/department'); // Make sure to replace '/listuser' with the correct route
        } else {
          // Show an alert for unsuccessful login
          alert('Invalid email or password');
        }
      })
      .catch (function (error) {
        console.log (error);
      });
  };
  return (
    <div className="main-panel">
    <div className="content">
      <div className="container-fluid">
        <p
          className="page-title card"
          style={{
            padding: '3%',
            borderRadius: '20px',
            fontSize: '20px',
            fontWeight:'bold',
            marginTop: '0%',
          }}
        >
          <span style={{marginTop: '0%'}}>Edit Department </span>
        </p>

        <div className="card" style={{borderRadius: '20px'}}>
          <div className="card-body">
          <div className="modal-body">
            <div className="form-group" style={{margin: '0%'}}>
              <label>Department Name</label>
              <input
              value={Department}
                onChange={e => {
                  SetDepartment (e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Department name"
                required=""
              />
            </div>
            <div className="form-group" style={{margin: '0%'}}>
                <label>Company Name</label>
                <select
                  onChange={e => {
                    SetCompany (e.target.value);
                  }}
                  className="form-control"
                  required=""
                >
                  <option value="">Select a company</option>
                  {CompanyValue &&
                    CompanyValue.map ((company, index) => (
                      <option key={index} value={company.id}>
                        {company.company_name}
                      </option>
                    ))};
                </select>
              </div>
           
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal">
                Close
              </button>
              <button
                onClick={() => submitdata ()}
                type="submit"
                data-dismiss="modal"
                name="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="footer">
      <div className="container-fluid">
        <nav className="pull-left">
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link" href="">
              Activity tracker
              </a>
            </li>
            {/* Add other footer links as needed */}
          </ul>
        </nav>
        <div className="copyright ml-auto">
            2024, made with
            {' '}
            <i className="la la-heart heart text-danger" />
            {' '}
            by
            {' '}
            <a href="https://qcodesinfotech.com" target='blank'>Qcodes</a>
          </div>
      </div>
    </footer>

   

  </div>
  )
}

export default EditDepartment